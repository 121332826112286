import React, {useState, useEffect} from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav} from 'react-bootstrap';
import {config} from '../Common/constant';

import { useContainerContext } from "../../store/ContainerContext";
import { SELLER_DETAILS } from "../../queries/common_use_query";


function SellerTab(props){

    const context = useContainerContext();
    const { seller_data } = context.state;
    const { sellerInfoDispatch } = context.actions;

    const { loading, error, data } = SELLER_DETAILS(props.get_seller_id);

    useEffect(() => {
        sellerInfoDispatch(data && data.sellers && data.sellers.length > 0 && data.sellers[0]);
        localStorage.setItem('seller_details', JSON.stringify(seller_data));
    }, [data]);

    return(
    <>
        <div className="top-content">
            <h2><strong>{seller_data && seller_data.name}</strong></h2>
        </div>

        <div className="seller-tabs">
            <ul>
                <li><Link to={`${config.seller_details}/${props.get_seller_id}`} className={props.tab_name === "Seller Details" ? 'actv' : ''}>Contact Details</Link></li>
                <li><Link to={`${config.seller_properties}/${props.get_seller_id}`} className={props.tab_name === "Seller Properties" ? 'actv' : ''}>Properties</Link></li>
                <li><Link to={`${config.seller_fact_find}/${props.get_seller_id}`} className={props.tab_name === "Seller Fact Find" ? 'actv' : ''}>Seller Fact Find</Link></li>
            </ul>
        </div>
    </>
    )
}

export default SellerTab
