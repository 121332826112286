import React, {useEffect, useState} from "react"
import { Link, navigate } from "gatsby"
import ProfileImg from "../../assests/images/avatar_img.svg";
import LogoImg from "../../assests/images/b-logo.svg";
import Dashboard_Icon from "../../assests/images/dashboard_icon.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
// import ProfileImg from "../assests/images/profile.svg";
// import LogoImg from "../assests/images/b-logo.svg";
import  "../../assests/scss/pages/app-dashboard.scss";
import  "../../assests/scss/pages/seller.scss";
import  "../../assests/scss/pages/sidebar.scss";
import  "../../assests/scss/pages/shrimmer.scss";

import {isLoggedIn, isBrowser, logout, getUser } from "../../services/auth"
import { useContainerContext } from "../../store/ContainerContext";

import {config} from './constant';


const LeftNav = (props) => {

  const [isAuth, setIsAuth] = useState(isLoggedIn());
  const context = useContainerContext();
  const { userInfoDispatch } = context.actions;
  const { logged_user_data } = context.state;

  //console.log('isAuth', isAuth);
  
  useEffect(() => {
    const user = getUser();
    userInfoDispatch(user.logged_user_data ? user.logged_user_data : {});
  },[isAuth]);

  // if(isAuth){
  //   console.log('isAuth', isAuth);
  //   //userInfoDispatch(localStorage.getItem('logged_user_data'))
  //   alert("test");
  // } else{
  //   if (isBrowser()) {
  //     navigate(`/`);
  //   }
  // }

  

  const logOut = () =>{
    if (isBrowser()) {
      localStorage.setItem('create_present','');
      navigate(`/`);
    }
    
    logout();
    setIsAuth(false);
    userInfoDispatch({});
  }

  console.log('isAuth', isAuth);


  return(
  <aside className="sidebar">
  <button className="hamburger">
      <span className="hambuger-lines"></span>
  </button> 
  <div className="sidebar-wrap">
    <div className="profile-img">
      <img src={logged_user_data.photo ? logged_user_data.photo : ProfileImg} alt="profile-img" />
    </div>
  <ul className="nav-list">
      <li>
        <Link to={config.app_dashboard} className={`icons ${props.page_name && props.page_name === "Dashboard" ? 'active' : ''}`}>
          <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" viewBox="0 0 64 64"><g id="Layer_17" data-name="Layer 17"><path d="M52,32.75H34.25a1.5,1.5,0,0,0-1.5,1.5V52a1.5,1.5,0,0,0,1.5,1.5H48.62a4.89,4.89,0,0,0,4.88-4.88V34.25A1.5,1.5,0,0,0,52,32.75Z"/><path d="M48.62,10.5H34.25a1.5,1.5,0,0,0-1.5,1.5V29.75a1.5,1.5,0,0,0,1.5,1.5H52a1.5,1.5,0,0,0,1.5-1.5V15.38A4.89,4.89,0,0,0,48.62,10.5Z"/><path d="M29.75,32.75H12a1.5,1.5,0,0,0-1.5,1.5V48.62a4.89,4.89,0,0,0,4.88,4.88H29.75a1.5,1.5,0,0,0,1.5-1.5V34.25A1.5,1.5,0,0,0,29.75,32.75Z"/><path d="M29.75,10.5H15.38a4.89,4.89,0,0,0-4.88,4.88V29.75a1.5,1.5,0,0,0,1.5,1.5H29.75a1.5,1.5,0,0,0,1.5-1.5V12A1.5,1.5,0,0,0,29.75,10.5Z"/></g></svg>
          </span>
        <span>
          Dashboard
        </span>              
        </Link>
      </li>

      <li>
        <Link to={config.seller_link} className={`icons ${props.page_name && props.page_name === "Seller" ? 'active' : ''}`}>
          <span>
          <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 12C24.9844 12 27 9.98437 27 7.5C27 5.01562 24.9844 3 22.5 3C20.0156 3 18 5.01562 18 7.5C18 9.98437 20.0156 12 22.5 12ZM22.5 5.25C23.7422 5.25 24.75 6.25781 24.75 7.5C24.75 8.74219 23.7422 9.75 22.5 9.75C21.2578 9.75 20.25 8.74219 20.25 7.5C20.25 6.25781 21.2578 5.25 22.5 5.25ZM12.7547 12.9375C12.1969 12.9375 11.6344 13.0172 11.0906 13.1859C10.425 13.3875 9.72656 13.5 8.99531 13.5C8.26406 13.5 7.56562 13.3875 6.9 13.1859C6.35625 13.0219 5.79375 12.9375 5.23594 12.9375C3.53437 12.9375 1.87969 13.6969 0.909375 15.1359C0.3375 15.9984 0 17.0344 0 18.15V20.25C0 21.4922 1.00781 22.5 2.25 22.5H15.75C16.9922 22.5 18 21.4922 18 20.25V18.15C18 17.0344 17.6625 15.9984 17.0812 15.1359C16.1109 13.6969 14.4563 12.9375 12.7547 12.9375ZM15.75 20.25H2.25V18.15C2.25 16.7953 3.1125 15.6375 4.31719 15.1922C4.8 15.0141 5.32969 15.0187 5.8125 15.1922C6.84844 15.5625 7.92188 15.75 9 15.75C10.0781 15.75 11.1469 15.5625 12.1922 15.1922C12.675 15.0187 13.2047 15.0141 13.6875 15.1922C14.8922 15.6328 15.7547 16.7953 15.7547 18.15V20.25H15.75ZM9 12C11.9016 12 14.25 9.65156 14.25 6.75C14.25 3.84844 11.9016 1.5 9 1.5C6.09844 1.5 3.75 3.84844 3.75 6.75C3.75 9.65156 6.09844 12 9 12ZM9 3.75C10.6547 3.75 12 5.09531 12 6.75C12 8.40469 10.6547 9.75 9 9.75C7.34531 9.75 6 8.40469 6 6.75C6 5.09531 7.34531 3.75 9 3.75ZM29.2359 14.8641C28.425 13.6641 27.0469 13.0312 25.6312 13.0312C24.3281 13.0312 24 13.5 22.5 13.5C21 13.5 20.6719 13.0312 19.3688 13.0312C18.7453 13.0312 18.1406 13.1719 17.5781 13.4109C17.85 13.6875 18.1078 13.9734 18.3281 14.2969C18.5484 14.625 18.7313 14.9719 18.8906 15.3281C19.0453 15.2953 19.2047 15.2766 19.3688 15.2766C20.175 15.2766 20.7563 15.7453 22.5 15.7453C24.2531 15.7453 24.8203 15.2766 25.6312 15.2766C26.3672 15.2766 27.0141 15.5906 27.3703 16.1156C27.6188 16.4859 27.75 16.9172 27.75 17.3672V18.75H19.5V20.25C19.5 20.5078 19.4719 20.7562 19.425 21H28.125C29.1609 21 30 20.1609 30 19.125V17.3766C30 16.4437 29.7188 15.5812 29.2359 14.8641Z" fill="white"/>
          </svg>
          </span>
        <span>
          Sellers
        </span>              
        </Link>
      </li>

      
      {/* <li>
        <Link to="#" className="icons">
         <span>
         <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M7.92857 15.4286H5.78571C5.43214 15.4286 5.14286 15.1393 5.14286 14.7857V12.6429C5.14286 12.2893 5.43214 12 5.78571 12H7.92857C8.28214 12 8.57143 12.2893 8.57143 12.6429V14.7857C8.57143 15.1393 8.28214 15.4286 7.92857 15.4286ZM13.7143 14.7857V12.6429C13.7143 12.2893 13.425 12 13.0714 12H10.9286C10.575 12 10.2857 12.2893 10.2857 12.6429V14.7857C10.2857 15.1393 10.575 15.4286 10.9286 15.4286H13.0714C13.425 15.4286 13.7143 15.1393 13.7143 14.7857ZM18.8571 14.7857V12.6429C18.8571 12.2893 18.5679 12 18.2143 12H16.0714C15.7179 12 15.4286 12.2893 15.4286 12.6429V14.7857C15.4286 15.1393 15.7179 15.4286 16.0714 15.4286H18.2143C18.5679 15.4286 18.8571 15.1393 18.8571 14.7857ZM13.7143 19.9286V17.7857C13.7143 17.4321 13.425 17.1429 13.0714 17.1429H10.9286C10.575 17.1429 10.2857 17.4321 10.2857 17.7857V19.9286C10.2857 20.2821 10.575 20.5714 10.9286 20.5714H13.0714C13.425 20.5714 13.7143 20.2821 13.7143 19.9286ZM8.57143 19.9286V17.7857C8.57143 17.4321 8.28214 17.1429 7.92857 17.1429H5.78571C5.43214 17.1429 5.14286 17.4321 5.14286 17.7857V19.9286C5.14286 20.2821 5.43214 20.5714 5.78571 20.5714H7.92857C8.28214 20.5714 8.57143 20.2821 8.57143 19.9286ZM18.8571 19.9286V17.7857C18.8571 17.4321 18.5679 17.1429 18.2143 17.1429H16.0714C15.7179 17.1429 15.4286 17.4321 15.4286 17.7857V19.9286C15.4286 20.2821 15.7179 20.5714 16.0714 20.5714H18.2143C18.5679 20.5714 18.8571 20.2821 18.8571 19.9286ZM24 6V24.8571C24 26.2768 22.8482 27.4286 21.4286 27.4286H2.57143C1.15179 27.4286 0 26.2768 0 24.8571V6C0 4.58036 1.15179 3.42857 2.57143 3.42857H5.14286V0.642857C5.14286 0.289286 5.43214 0 5.78571 0H7.92857C8.28214 0 8.57143 0.289286 8.57143 0.642857V3.42857H15.4286V0.642857C15.4286 0.289286 15.7179 0 16.0714 0H18.2143C18.5679 0 18.8571 0.289286 18.8571 0.642857V3.42857H21.4286C22.8482 3.42857 24 4.58036 24 6ZM21.4286 24.5357V8.57143H2.57143V24.5357C2.57143 24.7125 2.71607 24.8571 2.89286 24.8571H21.1071C21.2839 24.8571 21.4286 24.7125 21.4286 24.5357Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="24" height="27.4286" fill="white"/>
            </clipPath>
            </defs>
          </svg>
         </span>
        <span>
          Calendar
        </span>
        </Link>
      </li> */}

      <li>
        <Link to={config.search_link} className={`icons ${props.page_name && props.page_name === "Search" ? 'active' : ''}`}>
         <span> 
         <svg  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.8086 25.643L21.1695 19.0039C21.0438 18.8781 20.8797 18.8125 20.7047 18.8125H19.9828C21.7055 16.8164 22.75 14.2188 22.75 11.375C22.75 5.09141 17.6586 0 11.375 0C5.09141 0 0 5.09141 0 11.375C0 17.6586 5.09141 22.75 11.375 22.75C14.2188 22.75 16.8164 21.7055 18.8125 19.9828V20.7047C18.8125 20.8797 18.8836 21.0438 19.0039 21.1695L25.643 27.8086C25.9 28.0656 26.3156 28.0656 26.5727 27.8086L27.8086 26.5727C28.0656 26.3156 28.0656 25.9 27.8086 25.643ZM11.375 20.125C6.54063 20.125 2.625 16.2094 2.625 11.375C2.625 6.54063 6.54063 2.625 11.375 2.625C16.2094 2.625 20.125 6.54063 20.125 11.375C20.125 16.2094 16.2094 20.125 11.375 20.125Z" fill="white"/>
          </svg>
         </span>
        <span>
          Search
        </span>
        </Link>
      </li>
      <li>
        <a href="javascript:;" onClick={logOut}>
          <span>
            <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z" fill="white" />
              <path d="M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z" fill="white" />
            </svg>
          </span>
          <span>Logout</span>              
        </a>
      </li>
      {/* <li>
        <Link to="#" className="icons">
          <span>
          <svg width="25" height="5" viewBox="0 0 25 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.5" cy="2.5" r="2.5" fill="white"/>
            <circle cx="12.5" cy="2.5" r="2.5" fill="white"/>
            <circle cx="22.5" cy="2.5" r="2.5" fill="white"/>
          </svg>
          </span>
        <span>
          More
        </span>
        </Link>
      </li> */}
  </ul>
  {/* nav-list */}
  <Link to={config.app_dashboard} className="b-logo-img">
    <img src={LogoImg} alt="b-logo" />
  </Link>
  </div>
  </aside>
  )
}

export default LeftNav
