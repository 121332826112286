import React, {useState, useEffect} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"
import SellerTab from "../components/modules/seller-tab"

import mapCardImg from "../assests/images/gallery1.jpg";

import { SELLER_PROPERTIES } from "../queries/common_use_query";
import {config} from '../components/Common/constant';

const Seller = (props) => {

  let path_name = (props.location.pathname).split('/');
  let get_seller_id = path_name[path_name.length-1];

    const [property_list, setPropertyList] = useState([]);
    
    const { loading, error, data } = SELLER_PROPERTIES(get_seller_id);
    useEffect(() => {
        setPropertyList(data && data.properties && data.properties.length > 0 && data.properties);
    }, [data]);


  return(

    <>
      <SEO title="Seller" description="Seller" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Seller" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">

                    <SellerTab tab_name="Seller Properties" get_seller_id={get_seller_id} />
                    {/* Tab Content */}

                    <div className="sell_properties">
                        <Row>
                            <Col lg={12} md={12}>
                                <Row>
                                {
                                    property_list && property_list.length > 0 && property_list.map((property, index) => {

                                        return(
                                            <Col lg={4} md={4}>
                                                <div className="map-card">
                                                    <Link to={`${config.property_details}/${property.id}`}>
                                                        <figure>
                                                        <img src={mapCardImg} alt="map-img" />
                                                        </figure>
                                                        <div className="card-body">
                                                            <h3>{property.address}</h3>
                                                            <div className="card-text">
                                                                <p>£310,000</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* card-body */}
                                            </Col>
                                        )
                                    })
                                }                                    
                                </Row>

                            </Col>    
                        </Row>
                    </div>
              </div>

              
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default Seller